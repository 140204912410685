import React, { useState, useEffect } from 'react';
import '../styles/Ongoing.css';

const Ongoing = () => {
  const projects = [
    {
      name: 'Krishna Greens Midlake III',
      location: 'DB Road ',
      price: '₹1099 Per SQFT.',
      proximity: ' Just 20 km from Hebbal',
      images: ['/plot/land1.webp', ], // Replace with actual image paths
      amenities: [
        { name: 'Swimming Pool', icon: '/icons/swim.png' },
        { name: 'Gym', icon: '/icons/gym1.png' },
        { name: 'Clubhouse', icon: '/icons/club.png' },
        { name: 'Sewage', icon: '/icons/sewage.png' },
        { name: 'Adults Gardens', icon: '/icons/park.png' },
        { name: 'Underground Electrical Supply Lines', icon: '/icons/elec.png' },
        { name: 'Sewage Lines', icon: '/icons/sew2.png' },
        { name: 'Overhead Water tank', icon: '/icons/tank.png' },
        { name: 'Underground Water Connection', icon: '/icons/under.png' },
        { name: 'Overhead Water tank', icon: '/icons/tank.png' },
        { name: 'Septic Tank', icon: '/icons/septic.png' },
      ],

    },
    {
      name: 'Krishna Greens North Star',
      location: 'Avati, Devanahalli',
      price: '₹1299 Per SQFT',
      images: ['/plot/land6.jpg'], // Replace with actual image paths
      amenities: [
        { name: '60,40,30 Feet Roads', icon: '/icons/road.png' },
        { name: 'Underground Water Connection', icon: '/icons/under.png' },
        { name: 'Rain Water Harvesting', icon: '/icons/rain.png' },
        { name: 'Avenue Roads', icon: '/icons/avenue.png' },
        { name: 'Imported coloured flowers', icon: '/icons/flowers.png' },
        { name: 'Clubhouse', icon: '/icons/club.png' },
        { name: 'Solar Lights', icon: '/icons/solar.png' },
        { name: 'Amphe Theatre', icon: '/icons/theatre.png' },
        { name: 'Swimming Pool', icon: '/icons/swim.png' },
        { name: '24hrs Security', icon: '/icons/sec1.png' },
        { name: 'Chidrens Park', icon: '/icons/children.png' },
        { name: 'Underground Electrical Supply Lines', icon: '/icons/elec.png' },
        { name: 'Forest Trees', icon: '/icons/trees.png' },
      ],
    },
    {
      name: 'Hoyasala Nagara',
      location: 'NH 207, Raghunathapura Devanahalli',
      price: '₹13 Lakhs onwards',
      
      images: ['/plot/plot2.jpg'], // Replace with actual image paths
      amenities: [
        { name: '80 Feet Road ', icon: '/icons/road.png' },
        { name: 'Underground Sewage', icon: '/icons/sew1.png' },
        { name: 'Rain Water Harvesting', icon: '/icons/rain.png' },
        { name: 'Forest Trees', icon: '/icons/trees.png' },
        { name: 'Chidrens Park', icon: '/icons/children.png' },
        { name: 'Avenue Roads', icon: '/icons/avenue.png' },
        { name: 'Club House', icon: '/icons/club.png' },
        { name: 'Swimming Pool', icon: '/icons/swim.png' },
        { name: 'Street Light', icon: '/icons/light1.png' },
        { name: '24hrs Security', icon: '/icons/sec1.png' },
      ],
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(true);

  // Automatically change the slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        (prevSlide + 1) % projects[0].images.length
      );
      setIsTransitioning(true);
    }, 5000); // 5 seconds
    return () => clearInterval(interval);
  }, [projects]);

  const getSlideStyle = () => ({
    transform: `translateX(-${currentSlide * 100}%)`,
    transition: isTransitioning ? 'transform 1s ease-in-out' : 'none',
  });

  return (
    <div className="ongoing-container">
      {projects.map((project, index) => (
        <div key={index} className="project-section">
          {/* Image Slider */}
          <div className="image-slider">
            <div className="slides" style={getSlideStyle()}>
              {project.images.map((image, i) => (
                <img
                  key={i}
                  src={image}
                  alt={project.name}
                  className="project-image"
                />
              ))}
              <img
                src={project.images[0]} // Duplicate the first image for looping
                alt="First Slide"
                className="project-image"
              />
            </div>
          </div>

          {/* Project Details and Amenities */}
          <div className="details-and-amenities">
            <div className="project-details">
              <h2>{project.name}</h2>
              <p>
                <b>Location:</b> {project.location}
              </p>
              <p>
                <b>Price:</b> {project.price}
              </p>
              
            </div>
            <div className="amenities-section">
              <h3>Amenities</h3>
              <div className="amenities-list">
                {project.amenities.map((amenity, i) => (
                  <div key={i} className="amenity-item">
                    <img src={amenity.icon} alt={amenity.name} className="amenity-icon" />
                    <p>{amenity.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Ongoing;