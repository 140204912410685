import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll'; // For smooth scrolling
import { useNavigate, useLocation } from 'react-router-dom'; // To manage navigation and current location
import '../styles/Header.css'; // Keeping your existing CSS intact
import { Link } from 'react-router-dom';

const Header = () => {
  const [projectsDropdownOpen, setProjectsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // To determine the current page

  const toggleProjectsDropdown = () => {
    setProjectsDropdownOpen(!projectsDropdownOpen);
  };

  const handleNavClick = () => {
    setProjectsDropdownOpen(false); // Close the dropdown when clicking on any link
  };

  const navigateToSection = (sectionId) => {
    if (location.pathname !== '/') {
      // Navigate to the home page first if not already there
      navigate('/');
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 0); // Scroll after navigation
    } else {
      scrollToSection(sectionId);
    }
  };

  const scrollToSection = (sectionId) => {
    const target = document.getElementById(sectionId);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <header>
      <section className="image">
        <img src="/diamond.png" alt="logo" height={60} width={60} />
        <section className="gif-container">
          <img src="/para.gif" alt="Animated GIF" width="100%" height="60px" />
        </section>
      </section>

      <nav>
        <br />
        <section className="nav-bar">
          {/* Home link */}
          <ScrollLink to="home" className="nav-link" smooth={true} duration={500} offset={-230} onClick={() => navigateToSection('home')}>
            <b> Home </b>
          </ScrollLink>
          {/* About link */}
          <Link to="/about" className="nav-link" onClick={handleNavClick}>
            <b> About </b>
          </Link>
          {/* Career link */}
          <Link to="/career" className="nav-link" onClick={handleNavClick}>
            <b> Career </b>
          </Link>
          {/* Projects dropdown */}
          <div
            className="projects-dropdown"
            onMouseEnter={toggleProjectsDropdown}
            onMouseLeave={toggleProjectsDropdown}
          >
            <span className="projects-link"> <b>Projects</b></span>
            {projectsDropdownOpen && (
              <div className="dropdown-menu">
                <Link to="/completed" className="dropdown-item" onClick={handleNavClick}>
                  Completed Projects
                </Link>
                <Link to="/ongoing" className="dropdown-item" onClick={handleNavClick}>
                  Ongoing Projects
                </Link>
               
                <ScrollLink to="upcoming-projects" smooth={true} duration={500} offset={-230} onClick={() => navigateToSection('upcoming-projects')}>
                  Upcoming Projects
                </ScrollLink>
              </div>
            )}
          </div>
          <div>
            <Link to="/leader" className='nav-link' onClick={handleNavClick}><b>Leaders</b></Link>
          </div>
          {/* Contact link */}
          <ScrollLink to="address" className="nav-link" smooth={true} duration={500} offset={-230} onClick={() => navigateToSection('contact')}>
            <b> Contact </b>
          </ScrollLink>
        </section>
      </nav>
    </header>
  );
};

export default Header;
