import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home'; 
import Contact from './components/Carrer';
import About from './components/About';
import Career from './components/Carrer';
import Leader from './components/leader';
import Login from './components/Header'; // Import the Login component
import Completed from './components/Completed';
import Ongoing from './components/Ongoing';
import './styles/font.css';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="main-content">
          <main>
            <Routes>
              <Route path="/" element={<Home />} /> {/* Default route is now the homepage */}
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/career" element={<Career />} />
              <Route path="/leader" element={<Leader />} />
              <Route path="/login" element={<Login />} /> 
              <Route path="/completed" element={<Completed /> }/>
              <Route path="/ongoing" element={<Ongoing />} />  {/* Add the login route */}
            </Routes>
          </main>
        </div>
 
      </div>
    </Router>
  );
}

export default App;
