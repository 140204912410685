import React from 'react';
import { Link as ScrollLink } from 'react-scroll'; // For smooth scrolling
import { Link } from 'react-router-dom'; // For route navigation
import '../styles/Carrer.css';


const Career = () => {
  return (
    <div>
      <section id="career">
        <div className="crown">
        <h2>Careers at Diamond Crown</h2></div>
        <div className="paragraph">
        <p>
          Are you looking to grow your career in the dynamic world of real estate? <b>Diamond Crown</b> offers exciting opportunities for passionate individuals who are driven by success, innovation, and delivering exceptional service. As a rapidly growing company in the real estate market, we are always on the lookout for talented professionals to join our team and contribute to our mission of providing top-quality land and plot sales to clients.
        </p>
        <p>
          At Diamond Crown, we believe that our employees are the cornerstone of our success. We foster a supportive and collaborative work environment where your talents are nurtured, and your growth is encouraged. Whether you're a seasoned real estate professional or someone looking to start their journey in this industry, Diamond Crown is the place where you can thrive and make a meaningful impact.
        </p></div><br/>
        <div className="why">
        <p><b>Why Work at Diamond Crown?</b></p></div>
        <div className='growth'>
        <ul>
          <li><b>Growth Opportunities :</b> As a company that’s expanding, we provide numerous opportunities for career advancement. You’ll have the chance to grow your skills, take on new challenges, and shape your career path.</li>
          <li><b>Innovative Environment :</b> Real estate is constantly evolving, and so are we. At Diamond Crown, you’ll be working with cutting-edge tools, marketing strategies, and industry best practices to stay ahead in the market.</li>
          <li><b>Collaborative Culture :</b> We believe in teamwork and fostering a positive work environment. You’ll work alongside talented professionals who share your passion for excellence and are committed to achieving shared goals.</li>
          <li><b>Customer-Centric Approach :</b> At Diamond Crown, our customers are at the heart of everything we do. We are committed to delivering outstanding service and exceeding expectations—values that we instill in our employees.</li>
          <li><b>Learning and Development :</b> We invest in our people by providing ongoing training, mentoring, and career development programs. Whether it's staying updated with real estate laws or learning new sales techniques, we ensure that you have the tools and resources to excel.</li>
        </ul></div>
        <div className="opening">
          <ul>
            <li><b>Current Openings</b></li><br/>
            <li><b>Job-Title :</b> Sales & Marketing Executives.</li>
            <li><b>Location :</b> Bangalore.</li>
            <li><b>Job Type :</b> Full-Time, Permanent.</li>
            <li><b>Experience :</b> 0-1 Year.</li>
            <li><b>Call us :</b> +91 76187 97821.</li>
        </ul>
        </div>
        <div className='response'>
          <b>Responsibilities :</b>
          <ul>
            <li>Identify and reach out to potential clients interested in purchasing land or plots.</li>
            <li>Provide detailed information about properties and guide clients through the buying process.</li>
            <li>Build and maintain strong client relationships to ensure repeat business and referrals.</li>
            <li>Achieve and exceed sales targets.</li>
            <li>Develop and implement innovative marketing campaigns to promote our land and plot offerings.</li>
            <li>Manage online and offline marketing channels, including social media, email marketing, and property listings.</li>
            <li>Collaborate with the sales team to create compelling promotional materials and advertising strategies.</li>
            <li>Track and analyze marketing performance to optimize future campaigns.</li>
            <li>Assist clients with inquiries regarding properties, purchase processes, and documentation.</li>
            <li>Provide exceptional customer service through phone, email, and in-person interactions.</li>
            <li>Address and resolve client issues to ensure a positive experience with Diamond Crown.</li>
            <li>Maintain accurate records of customer interactions and transactions.</li>
          </ul>
        </div>
        <div className='qualify'>
          <b>Qualifications :</b>
          <ul>
            <li>Bachelor's degree in Marketing, Business, or a related field.</li>
            <li>Experience in digital marketing, including social media and Google Ads.</li>
            <li>Strong creative and analytical skills.</li>
            <li>Ability to work in a fast-paced environment and meet deadlines.</li>
            <li>Proven experience in real estate sales.</li>
            <li>Strong communication and negotiation skills.</li>
            <li>Ability to understand client needs and provide tailored solutions.</li>
            <li>Positive attitude and results-driven mindset.</li>
            <li>Strong problem-solving abilities and attention to detail.</li>
            <li>Experience in customer service.</li>
            <li>Ability to handle multiple tasks and prioritize effectively.</li>
          </ul>
        </div>
        <div className="paragraph">
            <p>
              Join the Diamond Crown Family. If you're looking to make a mark in the real estate industry, Diamond Crown is the perfect place to begin or elevate your career. We are committed to empowering our employees, fostering their growth, and achieving excellence, and providing the tools you need to succeess.
            </p>
            <p>At Diamond crown, your potential is our priority. Ready to take the next step? Send your resume and cover letter to [DiamondCrown@gmail.com] or explore our job listings to see how you can contribute to our vision of transforming the real estate landscape.</p>
            <br/><br/><br/><br/>
        </div>
        </section>
    </div>
  );
};

export default Career;
