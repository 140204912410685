import React from 'react';
import '../styles/leader.css';



const leader = () => {
  return (
        <div>
            <div className="about-md">
                <h2>Our MD’s Journey</h2>
                    <div className="para-md">
                        <p><span>Early Life:</span> Girish KS - A man's multifaceted personality, he was born on 02.03.1992 in Kitthur Village, Somwarpet Taluk, Kodagu District. He has led many walks of life in his career. He was born to parents Aruna K S and Shivanandha K S.</p>

                        <p><span>Education:</span> His educational background proved that he is someone who believes in learning that never ends. During his early years of childhood he completed his primary education in Government Primary School Kittoor and completed secondary school in Government High School Handli village. He completed his pre-graduate education at Vivekananda Pre-Graduate College, Kushalanagar, then completed his Bachelor's degree B.Com. graduate from Government First Grade College, Kushalanagar. later he completed to do his Post graduate of Masters degree M.Com from Kaveri College, Gonikoppa.
                        </p>

                        <p><span>Political and Social Activities:</span> Since his college days, he showed great interest in political issues, developmental organizations, and business,  so he did not pay much attention towards  his job career. Initially, he worked as the president for the student union at the college during his graduation. He worked as the District Secretary for the Human Rights and Anti-Corruption Organization—Delhi in Kodagu district. He also worked as the district secretary for the workers organization, INTUC, then as the district secretary of the Kodagu District Youth Congress. He continues to work as the District Secretary for  Virasaiva Lingayat Yuva Vedhika. In addition to working as the chairman of Belaku Charitable Trust, he has successfully run various types of businesses at a young age.
                        </p>

                        <p><span>Social Services:</span> He has done social services in various ways—donating money to underprivileged children's education,  people with poverty issues and had a big financial crisis in their lives,  helped in providing funds for foods to the needy and initiated a step forward to develop his rural area by providing a better infrastructure. He is also a part of  social media activism. 
                        </p>

                        <p><span>Entrepreneurship:</span> With the intention of building a career, he started his own business on a large scale and moved to Bangalore to become an entrepreneur,  in the initial stage he started with small group entering into marketing business, meanwhile he didnt let his lead down his time and power kept working on the goal and manifested  to his achievements and success. This  extended his marketing networking wide  with other competing  companies. He has not only made a difference for him but became a role model to the youths of our  future endeavors and now today he has given employment to more than 50 employees through a company called Diamond Crown Private Limited, and is running the company very smartly and responsibly.</p>
                    </div>
            </div>
        </div>
        );
        };

export default leader;
