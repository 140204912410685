import React from 'react';
import '../styles/About.css';



const About = () => {
  return (
    <div>
      <section id="about">
        <div className="head">
        <h2>About Us</h2>  </div> 
        <div className="para">
          <p>
            Welcome to Diamond Crown, your trusted partner in land and plot investments. For the past year, we have been helping individuals and families like yours discover the perfect location to build their future. Whether you're looking for a prime spot to construct your dream home or seeking a valuable investment opportunity, we are here to make it happen.
          </p>
          <p>
            At Diamond Crown, we believe that owning land is more than just a transaction—it’s a step toward security, prosperity, and long-term growth. Our team is passionate about providing properties that are not only legally sound and strategically located but also offer incredible value for money.
          </p></div>
          <div className='list'>
          <ul>
            <li><b>Prime Locations :</b> Our plots are carefully selected in thriving areas with high growth potential.</li>
            <li><b>Transparent Processes :</b> No hidden fees, no surprises—just clear and straightforward dealings.</li>
            <li><b>Customer-Focused Service :</b> We listen to your needs and work tirelessly to match you with the right property.</li>
            <li><b>Expert Guidance :</b> Whether you're a first-time buyer or a seasoned investor, our experienced team will walk you through every step of the process.</li>
          </ul></div>
          <div className="discover">
          <p>
            Discover the perfect place to start building your future with Diamond Crown. Explore our listings today, and take the first step toward owning land that suits your vision and goals.
          </p></div><br/>
          


          <div className="about-us">
          <div className="vision">
          <b><h3><div className="us">Vision</div></h3></b>
          <p>  To be a pioneering real estate partner, setting new standards in trust, 
    innovation, and quality by empowering clients to build their legacies through valuable land investments that shape a prosperous future.
    We aspire to transform the real estate landscape by offering opportunities that not only fulfill immediate aspirations 
    but also secure lasting value for generations to come. 
    Through a commitment to integrity, transparency, and customer satisfaction, 
    we aim to create enduring relationships and sustainable growth in every community we serve.
      </p>
      </div>
      <div className="mission">
        <b><h3>Mission</h3></b>
        <p>   To empower clients with comprehensive information, expert guidance,
    and seamless services that make land ownership accessible, transparent, and rewarding.
    We strive to transform real estate transactions by fostering trust, quality,
    and long-term satisfaction. Our mission is to be more than a service provider—to become a trusted partner 
    in every client’s journey, ensuring their investment aligns with their values and aspirations.
    Through continuous innovation and a commitment to excellence, we aim to create an experience where 
    clients feel confident, supported, and inspired to achieve their property dreams.
    </p>
    </div>
    <div className="goal">
      <b><h3>Goals</h3></b>
      <ul>
    <li>Educate Clients: Provide in-depth resources and tools to help clients make informed decisions about land investments.</li><br/>
    <li>Simplify the Buying Process: Streamline every stage of the land acquisition journey, from discovery to purchase, making it efficient and hassle-free.</li><br/>
    <li>Build Lasting Relationships: Foster ongoing trust and communication with clients, aiming for lifelong partnerships.</li><br/>
    <li>Sustain Growth: Consistently expand our land portfolio to meet client demand, ensuring diverse options and prime locations for investment.</li><br/>
        <br/>
        </ul>
        </div>
        </div>
      </section>
      
      
    </div>
  );
};

export default About;