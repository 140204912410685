import React from "react";
import "../styles/Completed.css";

const Completed = () => {
  const sites = [
    {
      image: "/site/pic2.jpg", // Replace with actual image URLs
      name: "Krishna Greens Midlake Phase I",
      location: "Location: DB Road",
      proximity:"Proximity: Just 30 KM from MG Road",
      price:  "Price: Rs.720 per SqFt.",
    },
    {
      image: "/site/pic5.jpg", // Replace with actual image URLs
      name: "Krishna Greens Midlake Phase II",
      location: "Location: DB Road",
      proximity:"Proximity: Just 20 km from Hebbal",
      price:  "Price: Rs.890 per SqFt.",
    },
    {
      image: "/site/pic6.jpg", // Replace with actual image URLs
      name: "Krishna Greens Westmist Phase I",
      location: "Location: Yelawala",
      proximity:"Proximity:  Just 15 from Mysore Bus Stop",
      price:  "Price: Rs.459 per SqFt.",
    },
    {
      image: "/site/pic10.jpg", // Replace with actual image URLs
      name: "Mysuru Sahakara Nagara",
      location: "Location: Yelwala",
      proximity:"Proximity: Just 3 kilometer fron Yelawala",
      price:  "Price: Rs.799 per SqFt.",
    },
  ];

  return (
    <div className="completed-container">
      {sites.map((site, index) => (
        <div key={index} className={`site-box site-box-${index % 2 === 0 ? "left" : "right"}`}><br/>
          <img src={site.image} alt={site.name} className="site-image" />
          <div className="site-details">
            <h3>{site.name}</h3>
            <p>{site.location}<br/>
            {site.proximity}<br/>
            {site.price} </p>
          </div>
        </div> 
      ))}
    </div>
  );
};

export default Completed;
