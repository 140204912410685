import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import '../styles/ChatPopup.css';

const ChatPopup = () => {
  const [isOpen, setIsOpen] = useState(true); // Start with the chatbox open
  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission
  const [isLoading, setIsLoading] = useState(false); // Loading state for submission
  const [errorMessage, setErrorMessage] = useState(''); // Error message state

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: ''
  });

  // Handle closing the chatbox
  const handleClose = () => {
    setIsOpen(false);
  };

  // Handle reopening the chatbox
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await fetch('/api/chat/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        console.log('Client details submitted:', formData);
        setFormData({
          name: '',
          phone: '',
          email: ''
        });
        setIsSubmitted(true); // Show confirmation message
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.error || 'Failed to submit details. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while submitting. Please check your network and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle closing the confirmation message
  const handleOkClick = () => {
    setIsSubmitted(false); // Hide the message box
  };

  return (
    <div className="ChatPopup-container">
      {isOpen ? (
        <div className="chatbox">
          <div className="chatbox-header">
            <h4>Diamond Crown</h4>
            <button className="close-btn" onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </button>
          </div>
          <div className="chatbox-content">
            <form onSubmit={handleSubmit} className="chat-form">
              <div className="form-group">
                <p><b>Enquire Here</b></p>
                <label htmlFor="name">Name :</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number :</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email :</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="submit-btn" disabled={isLoading}>
                {isLoading ? 'Submitting...' : <b>SUBMIT</b>}
              </button>
            </form>
            {/* Error message */}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
          {/* Confirmation Message Box */}
          {isSubmitted && (
            <div className="message-box-overlay">
              <div className="message-box">
                <p>You are all set. Our Experts will contact you soon.</p>
                <button className="ok-btn" onClick={handleOkClick}>
                  OK
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <button className="ChatPopup-icon" onClick={handleOpen}>
          <FontAwesomeIcon icon={faCommentDots} size="2x" />
        </button>
      )}
    </div>
  );
};

export default ChatPopup;
