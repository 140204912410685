import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatPopup from './ChatPopup';
import '../styles/Home.css';

    const sliderImages = [
      "/plot/slide1.jpg",
      "/plot/slide2.jpg",
      "/plot/slide3.jpg",
    ];

    const projectImages = [
      "/plot/plot.png",
      "/plot/plot2.jpg",
      "/plot/land2.webp",
      "/plot/land1.webp",
      "/plot/res1.jpg",
      "/plot/land8.jpg",
    ];

    const leadershipProfiles = [
      {
        name: "Mr. Gireesh Kitthur",
        position: "Managing Director",
        description:
          "Mr. Gireesh Kitthur, a visionary leader, has been instrumental in the growth of Diamondcrown through his focus on strategic innovation, operational excellence, and empowering the team. His dedication to sustainable development and customer-centric values continues to shape the company’s success.",
          image: "plot/gireesh.png",
      },  
      {
        name: "Mr. Ravi",
        position: "Chief Executive Officer",
        description:
          "Mr. Ravi, the CEO of Diamondcrown, has been the driving force behind the company’s rise as a trusted leader in the real estate sector. Through his vision and unwavering commitment, Diamondcrown has flourished, providing unparalleled service and high-quality land offerings.",
        image: "plot/ravi.jpg",
      },
      {
        name: "Mr. Puneeth",
        position: "Manager",
        description:
          "Mr. Puneeth, Manager at Diamondcrown, brings a dynamic approach to the company's leadership team. With her strategic insights and dedication to quality, she has been integral in driving Diamondcrown’s vision of excellence in the most strategic real estate industry.",
        image: "plot/puneeth.jpg",
      },
      {
        name: "Mr. Jagadeesh",
        position: "Manager",
        description:"Mr. Jagadeesh plays a pivotal role in overseeing project execution and ensuring timely delivery. His expertise in project planning and resource management enables Diamondcrown to maintain its high standards of quality and efficiency.",
        image: "plot/jagadeesh.jpg",
      },
      {
        name: "Mr. Suraj",
        position: "Manager",
        description:
"Mr. Suraj leads the marketing initiatives at Diamondcrown with her creative vision and strategic insight. His ability to connect with clients and effectively communicate the company’s values has been instrumental in building brand awareness.",
        image: "plot/suraj.jpg",
      },

    ];

  const Home = ({ auth, setAuth }) => {
  const navigate = useNavigate();
  const [showChatPopup, setShowChatPopup] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0); // For project images
  const [currentLeader, setCurrentLeader] = useState(0);

  // Show chat popup after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => setShowChatPopup(true), 2000);
    return () => clearTimeout(timer);
  }, []);

  // Automatic slideshow for slider
  useEffect(() => {
    const slideInterval = setInterval(() => {
      if (currentSlide === sliderImages.length) {
        setIsTransitioning(false);
        setCurrentSlide(0);
      } else {
        setIsTransitioning(true);
        setCurrentSlide((prevSlide) => prevSlide + 1);
      }
    }, 3000);
    return () => clearInterval(slideInterval);
  }, [currentSlide, sliderImages.length]);

  
  // Automatic sliding for project images
  useEffect(() => {
    const projectInterval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + 1 < projectImages.length ? prevIndex + 1 : 0
      );
    }, 3000);
    return () => clearInterval(projectInterval);
  }, [projectImages.length]);


  // Automatic slideshow for leadership profiles
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLeader((prev) => (prev + 1) % leadershipProfiles.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [leadershipProfiles.length]);


  const getSlideStyle = () => ({
    transform: `translateX(-${currentSlide * 100}%)`,
    transition: isTransitioning ? 'transform 0.5s ease-in-out' : 'none',
  });


  return (
    <div className="home-container">
          <section className="slider-container">
            <div className="slides" style={getSlideStyle()}>
              {sliderImages.map((src, index) => (
                <img src={src} alt={`Slide ${index + 1}`} className="slide" key={index} />
              ))}
              <img src={sliderImages[0]} alt="Slide 1 duplicate" className="slide" />
            </div>
          </section>
          <section id="home">
            <h1>Welcome to Diamond Crown</h1>
              <p>
                Welcome to <b>Diamond Crown </b>Bangalore, one of Bangalore’s Leading Reality companies. 
                Established in 2023, Diamond Crown Bangalore has been developing
                Residential Layouts for Housing Co-operative Societies across Bangalore and Mysore districts.
                All our projects are noted for their excellent location,
                high-end amenities, and affordable prices, which makes it ideal for living or investment.
              </p>
          </section>

<br/><br/>
          <div className="leaders">
            <div className="leader">
                <div className="leader-text">
                  <ul>
                      <li>
                        <b>LEADERSHIP</b>
                      </li><br/>
                      <li>{leadershipProfiles[currentLeader].description}</li>
                      <li><br/>
                        <b>{leadershipProfiles[currentLeader].name}</b>
                      </li>
                      <li>{leadershipProfiles[currentLeader].position}</li>
                  </ul>
                </div>
              <div className="leader-image">
                <img src={leadershipProfiles[currentLeader].image} alt={leadershipProfiles[currentLeader].name} />
              </div>
            </div>
              <div className="dot-navigation"><br/><br/>
                  {leadershipProfiles.map((_, index) => (
                    <span
                      key={index}
                      className={`dot ${currentLeader === index ? "active" : ""}`}
                      onClick={() => setCurrentLeader(index)}
                    ></span>
                  ))}
              </div>
          </div>
          <div className="managers">
             
          </div>
          <br/><br/>
          <section id="video">
                  <video width="100%" height="500" controls autoPlay muted>
                    <source src="/Northstar.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </section>
          <div className="contact-sec">
                <div className="address">
                  <h2>Contact Us</h2>
                      <p><br/>
                          <b>No.330/1, 2nd Floor Royal Crowne,<br/>
                        CQAL Layout, Sahakarnagar,<br/>
                        Kothihosahalli,Bangalore North,<br/>
                        Karnataka - 560092.<br/>
                        Phone Number : +91 7618797821 </b>
                      </p>
                  </div>
                  <div className="lists">
                    <ul>
                      <li>Career</li>
                      <li>Job Opportunity</li>
                      <li>Projects</li>
                      <li>Keep in Touch</li>
                      <li>Follow Us On</li>
                      <li>Our Location</li>
                    </ul>
                  </div>
                  <div className="topic">
                        <ul>
                          <li>Vision</li>
                          <li>Mission</li>
                          <li>Goal</li>
                        </ul>
                  </div>
                  <div className="links">
                    <div className="phone">
                        <a href="tel:+917618797821">
                          <img src="/icons/CALL.png" alt="Call" height={22} width={20} />
                        </a>
                    </div>
                    <div className="mail">
                        <a href="mailto:Diamondcrown2023@gmail.com">
                            <img src="icons/MAIL.png" alt="Mail" height={22} width={23} />
                        </a>
                    </div>
                    <div className="whatsapp">
                      <a href="https://wa.me/917618797821" target="_blank" rel="noopener noreferrer">
                        <img src="/icons/WAPP.png" alt="WhatsApp" height={24} width={22} />
                      </a>
                    </div>
                    <div className="facebook">
                      <a href="https://www.facebook.com/profile.php?id=61551949509926" target="_blank" rel="noopener noreferrer">
                        <img src="/icons/FB.png" alt="Facebook" height={22} width={17} />
                      </a>
                    </div>
                    <div className="twitter">
                      <a href="https://www.x.com/Diamondcro2023" target="_blank" rel="noopener noreferrer">
                        <img src="/icons/X.png" alt="Twitter" height={22} width={20} />
                      </a>
                    </div>
                    <div className="instagram">
                      <a href="https://www.instagram.com/employeeshousing" target="_blank" rel="noopener noreferrer">
                        <img src="/icons/INSTA.png" alt="Instagram" height={24} width={22} />
                      </a>
                    </div>
                    <div className="map">
                      <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer">
                        <img src="/icons/MAP.png" alt="Map" height={24} width={20} />
                      </a>
                    </div>
                  </div>
                </div>
      {showChatPopup && <ChatPopup closePopup={() => setShowChatPopup(false)} />}
    </div>
  );
};


export default Home;
